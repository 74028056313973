//import { graphql, withPrefix, Link } from 'gatsby';
import { graphql, StaticQuery, withPrefix } from "gatsby"
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import React, { Component } from "react";

import ContactFormFull from "../components/ContactFormFull";

import { FormattedMessage } from "gatsby-plugin-intl"

class ContactPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
          <Layout path={this.props.location.pathname}>
            <SEO title="Contact" />

            <div id="contact" className="container pt-md-5">
              <ContactFormFull />
            </div>

          </Layout>
    );
  }
}

export default ContactPage
